<template>
  <form-field :name="field.name">
    <v-select
      v-model="field.value"
      v-bind="field.props"
      :menu-props="{maxHeight:'auto'}"
      box
    >
      <template
        slot="item"
        slot-scope="data"
      >
        <v-list-tile-avatar v-if="avatar">
          <v-icon>{{ data.item.avatar }}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title v-html="data.item.code" />
          <v-list-tile-sub-title v-html="data.item.description" />
        </v-list-tile-content>
      </template>
    </v-select>
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  computed: {
    avatar: function () {
      for (const item of this.field.props.items) { if (item.avatar) return true }

      return false
    },
  },
}
</script>
